<template>
  <div id="reservationContainer">
    <DailyReportEdit :dailyReportId="dailyReportId"></DailyReportEdit>
  </div>
</template>

<script>
  import Vue from 'vue';
  import DailyReportEdit from "../../components/Sopotniki/reports/DailyReportEdit";


  export default {
    name: 'DailyReport',

    components: {
      DailyReportEdit
    },

    data() {
      return {
        dailyReportId: this.$route.params.dailyReportId ? parseInt(this.$route.params.dailyReportId) : null,
      }
    },

    computed: {},

    mounted() {
      const _this = this;
      // _this.getReservation();
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.dailyReportId = null;
      setTimeout(() => {next()}, 100);
    },

    methods: {
      // getReservation() {
      //   const _this = this;
      //
      //   _this.$vs.loading();
      // },

    }
  }
</script>

