<template>
  <div id="reservationEditContainer" class="relative">

    <div>

      <div class="sm:none md:flex-auto lg:flex xl:flex">

        <div class="sm:w-full md:w-full lg:w-full xl:w-full 2xl:w-2/3">
          <div class="sm:mr-0 md:mr-0 lg:mr-5 xl:mr-10">

            <div class="vx-row mb-2" v-if="dailyReportId && dailyReportData">
              <div class="vx-col w-full">
                <h1 v-if="dailyReportData.submitted">Preglej dnevno poročilo</h1>
                <h1 v-if="!dailyReportData.submitted">Oddaj dnevno poročilo</h1>
              </div>
            </div>
          </div>

          <div class="vx-row mt-5 mb-2" v-if="dailyReportId && dailyReportData && dailyReportData.submitted">
            <div class="vx-col w-full">
              <vs-alert :color="'success'" :title="'Dnevno poročilo zaključeno'" :active="true">
                Dnevno poročilo je že bilo zaključeno in oddano. Zadnja sprememba je bila opravljena dne:
                {{ moment(dailyReportData.updated_at).format('DD. MM. YYYY ob HH:mm:ss') }}.
              </vs-alert>
            </div>
          </div>

          <div class="vx-card mt-5">
            <div class="vx-card__header">
              <div class="vx-card__title">
                <h4 class="">Osnovni podatki</h4>
              </div>
            </div>
            <div class="vx-card__collapsible-content">
              <div class="vx-card__body">

                <div class="vx-row" v-if="dailyReportId && dailyReportData">
                  <div class="vx-col w-full">
                    <label class="vs-input--label">Datum</label>
                    <datepicker placeholder="Datum" :language="slSI" :format="customDateFormatter" :monday-first="true"
                                @input="onDateChange" v-model="dailyReportData.date" disabled></datepicker>
                  </div>
                </div>

                <div class="vx-col w-full mt-5" v-if="dailyReportTypesData">
                  <label for="dailyReportPurpose" class="vs-input--label">Tip</label>
                  <v-select id="dailyReportPurpose" :filter="fuseSearchName" :options="dailyReportTypesData" class=""
                            @input="() => { dailyReportData.type = dailyReportType; }"
                            v-model="dailyReportType" placeholder="Tip"
                            :getOptionLabel="option => option.name">
                    <template v-slot:option="option">
                      <vs-list-item
                        :title="option.name">
                      </vs-list-item>
                    </template>

                    <template v-slot:selected-option="option">
                      <vs-list-item
                        class="d-block"
                        :title="option.name">
                      </vs-list-item>
                    </template>

                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                    </template>
                  </v-select>

                  <div class="flex mt-1" v-if="$v.dailyReportData.type.id.$dirty">
                    <div class="w-full">
                      <vs-alert :active="!$v.dailyReportData.type.id.required" :color="'danger'">
                        Polje "Tip" ne sme biti prazno.
                      </vs-alert>
                    </div>
                  </div>

                </div>

                <div class="vx-row mt-5" v-if="volunteersData && dailyReportData">

                  <div class="vx-col w-full">
                    <label class="vs-input--label">
                      Prostovoljec
                    </label>
                    <v-select :filter="fuseSearch" :options="volunteersData" class="" v-model="volunteer"
                              @input="() => { dailyReportData.volunteer = volunteer; }"
                              placeholder="Prostovoljec"
                              >
                      <template v-slot:option="option">
                        <vs-list-item
                          :title="option.first_name + ' ' + option.last_name">
                          <template slot="avatar">
                            <div class="avatarWithStatus mr-5">
                              <vs-avatar size="large" :src="$globalFunctions.getUserAvatar(option.profile_image)"/>
                              <vs-avatar v-if="option.active === 1" size="small" class="status" color="success"
                                         icon="check_circle"/>
                              <vs-avatar v-if="option.active === 0" size="small" class="status" color="danger"
                                         icon="radio_button_unchecked"/>
                            </div>
                          </template>
                        </vs-list-item>
                      </template>

                      <template v-slot:selected-option="option">
                        <vs-list-item
                          class="d-block"
                          :title="option.first_name + ' ' + option.last_name">
                          <template slot="avatar">
                            <div class="avatarWithStatus mr-5">
                              <vs-avatar size="large" :src="$globalFunctions.getUserAvatar(option.profile_image)"/>
                              <vs-avatar v-if="option.active === 1" size="small" class="status" color="success"
                                         icon="check_circle"/>
                              <vs-avatar v-if="option.active === 0" size="small" class="status" color="danger"
                                         icon="radio_button_unchecked"/>
                            </div>
                          </template>
                        </vs-list-item>
                      </template>

                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                      </template>
                    </v-select>

                    <div class="flex mt-1" v-if="$v.dailyReportData.volunteer.id.$dirty">
                      <div class="w-full">
                        <vs-alert :active="!$v.dailyReportData.volunteer.id.required" :color="'danger'">
                          Polje "Prostovoljec" ne sme biti prazno.
                        </vs-alert>
                      </div>
                    </div>


                  </div>
                </div>

                <div class="vx-row mt-5" v-if="carsData">

                  <div class="vx-col w-full">
                    <label class="vs-input--label">
                      Vozilo
                    </label>
                    <v-select :filter="fuseSearchName" :options="carsData" v-model="car"
                              @input="() => { dailyReportData.car = car; }"
                              placeholder="Vozilo"
                              :getOptionLabel="option => option.name">
                      <template v-slot:option="option">
                        <vs-list-item
                          :title="option.name"
                          :subtitle="option.registrationNumber">
                          <template slot="avatar">
                            <vs-avatar size="large" class="mr-5" :style="'background-color:' + option.color"
                                       :text="option.name"/>
                          </template>
                        </vs-list-item>
                      </template>

                      <template v-slot:selected-option="option">
                        <vs-list-item
                          :title="option.name"
                          :subtitle="option.registrationNumber">
                          <template slot="avatar">
                            <vs-avatar size="large" class="mr-5" :style="'background-color:' + option.color"
                                       :text="option.name"/>
                          </template>
                        </vs-list-item>
                      </template>

                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                      </template>
                    </v-select>

                    <div class="flex mt-1" v-if="$v.dailyReportData.car.id.$dirty">
                      <div class="w-full">
                        <vs-alert :active="!$v.dailyReportData.car.id.required" :color="'danger'">
                          Polje "Vozilo" ne sme biti prazno.
                        </vs-alert>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>

          <ReservationsList style="position: relative; z-index: 0"
                            v-if="dailyReportData.date && dailyReportData.volunteer"
                            :reservations-date="moment(dailyReportData.date).valueOf()"
                            :volunteer-id="dailyReportData.volunteer.id"></ReservationsList>

          <div class="vx-card mt-5">
            <div class="vx-card__header">
              <div class="vx-card__title">
                <h4 class="">Opombe prostovoljca</h4>
              </div>
            </div>
            <div class="vx-card__collapsible-content">
              <div class="vx-card__body">
                <div class="flex">
                  <div class="w-full">
                    <vs-textarea class="w-full" rows="6" v-model="dailyReportData.notes"/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="vx-card mt-5">
            <div class="vx-card__header">
              <div class="vx-card__title">
                <h4 class="">Prostovoljsko delo, stanje na števcu in v blagajni</h4>
              </div>
            </div>
            <div class="vx-card__collapsible-content">
              <div class="vx-card__body">

                <div class="grid grid-cols-3 gap-2 mt-0">
                  <div>
                    <label class="vs-input--label">Ura začetka prostovoljskega dela</label>
                    <flat-pickr name="volunteerHoursStart" :config="configTimePicker"
                                v-model="dailyReportData.volunteerHoursStart"
                                placeholder="Ura začetka prostovoljskega dela"
                                class="w-full" @on-change="calculateVolunteerHoursTotal"/>

                    <div class="flex mt-1" v-if="$v.dailyReportData.volunteerHoursStart.$dirty">
                      <div class="w-full">
                        <vs-alert :active="!$v.dailyReportData.volunteerHoursStart.required" :color="'danger'">
                          Polje "Ura začetka prostovoljskega dela" ne sme biti prazno.
                        </vs-alert>
                      </div>
                    </div>

                  </div>
                  <div>
                    <label class="vs-input--label">Ura zaključka prostovoljskega dela</label>
                    <flat-pickr name="volunteerHoursEnd" :config="configTimePicker"
                                v-model="dailyReportData.volunteerHoursEnd"
                                placeholder="Ura zaključka prostovoljskega dela"
                                class="w-full" @on-change="calculateVolunteerHoursTotal"/>

                    <div class="flex mt-1" v-if="$v.dailyReportData.volunteerHoursEnd.$dirty">
                      <div class="w-full">
                        <vs-alert :active="!$v.dailyReportData.volunteerHoursEnd.required" :color="'danger'">
                          Polje "Ura zaključka prostovoljskega dela" ne sme biti prazno.
                        </vs-alert>
                      </div>
                    </div>

                  </div>
                  <div v-if="dailyReportData">
                    <vs-input icon="schedule" class="w-full" label="Št. ur prostovoljskega dela" type="text"
                              v-model="dailyReportData.volunteerHoursTotal" disabled/>
                  </div>
                </div>

                <div class="grid grid-cols-3 gap-2 mt-5">
                  <div>
                    <vs-input class="w-full" label="Št. kilometrov na števcu (začetek dneva)" type="number" min="0"
                              step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              v-model="dailyReportData.odometerStart" @blur="calculateOdometerTotal"/>

                    <div class="flex mt-1" v-if="$v.dailyReportData.odometerStart.$dirty">
                      <div class="w-full">
                        <vs-alert :active="!$v.dailyReportData.odometerStart.required" :color="'danger'">
                          Polje "Št. kilometrov na števcu (začetek dneva)" ne sme biti prazno.
                        </vs-alert>
                      </div>
                    </div>

                  </div>
                  <div>
                    <vs-input class="w-full" label="Št. kilometrov na števcu (konec dneva)" type="number" min="0"
                              step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              v-model="dailyReportData.odometerEnd" @blur="calculateOdometerTotal"/>

                    <div class="flex mt-1" v-if="$v.dailyReportData.odometerEnd.$dirty">
                      <div class="w-full">
                        <vs-alert :active="!$v.dailyReportData.odometerEnd.required" :color="'danger'">
                          Polje "Št. kilometrov na števcu (konec dneva)" ne sme biti prazno.
                        </vs-alert>
                      </div>
                    </div>

                  </div>
                  <div>
                    <vs-input icon="directions" class="w-full" label="Št. prevoženih kilometrov glede na števec"
                              v-model="dailyReportData.odometerTotal" disabled=""/>
                  </div>
                </div>

                <div class="grid grid-cols-3 gap-2 mt-5">
                  <div>
                    <vs-input class="w-full" label="Stanje v blagajni (začetek dneva)" type="number" min="0" step="0.1"
                              v-model="dailyReportData.cashierStart" @blur="calculateCashierTotal"/>
                  </div>
                  <div>
                    <vs-input class="w-full" label="Stanje v blagajni (konec dneva)" type="number" min="0" step="1"
                              v-model="dailyReportData.cashierEnd" @blur="calculateCashierTotal"/>
                  </div>
                  <div>
                    <vs-input icon="euro_symbol" class="w-full" label="Dnevna bilanca"
                              v-model="dailyReportData.cashierTotal" disabled=""/>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="vx-card mt-5">
            <div class="vx-card__header">
              <div class="vx-card__title">
                <h4 class="">Poraba donacij v enoti</h4>
              </div>
            </div>
            <div class="vx-card__collapsible-content">
              <div class="vx-card__body">
                <div class="grid grid-cols-3 gap-2 mt-0">
                  <div class="vx-col" v-if="lunchDonorOptions && lunchDonorOptions.length > 0">
                    <label class="vs-input--label" for="lunchDonorId">Kosilo</label>
                    <v-select id="lunchDonorId" :options="lunchDonorOptions" class=""
                              v-model="dailyReportData.lunchDonorId" placeholder="kosilo"
                              :reduce="item => item.donor.id"
                              :getOptionLabel="option => option.donor.companyDisplayName" @input="() => { $forceUpdate(); }">
                      <template v-slot:option="option">
                        <vs-list-item
                          :title="option.donor.companyDisplayName"
                          :subtitle="option.donor.companyName">
                        </vs-list-item>
                      </template>

                      <template v-slot:selected-option="option">
                        <vs-list-item
                          class="d-block"
                          :title="option.donor.companyDisplayName"
                          :subtitle="option.donor.companyName">
                        </vs-list-item>
                      </template>

                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                      </template>
                    </v-select>
<!--                    <div class="flex mt-1" v-if="$v.dailyReportData.launch.id.$dirty">-->
<!--                      <div class="w-full">-->
<!--                        <vs-alert :active="!$v.dailyReportData.launch.id.required" :color="'danger'">-->
<!--                          Polje "Kosilo" ne sme biti prazno.-->
<!--                        </vs-alert>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                  <div class="vx-col" v-if="breakfastDonorOptions && breakfastDonorOptions.length > 0">
                    <label class="vs-input--label" for="breakfastDonorId">Zajtrk</label>
                    {{dailyReportData.breakfastDonorId}}
                    <v-select id="breakfastDonorId" :options="breakfastDonorOptions" class=""
                              :reduce="item => item.donor.id"
                              v-model="dailyReportData.breakfastDonorId" placeholder="zajtrk"
                              :getOptionLabel="option => option.donor.companyDisplayName" @input="() => { $forceUpdate(); }">
                      <template v-slot:option="option">
                        <vs-list-item
                          :title="option.donor.companyDisplayName"
                          :subtitle="option.donor.companyName">
                        </vs-list-item>
                      </template>

                      <template v-slot:selected-option="option">
                        <vs-list-item
                          class="d-block"
                          :title="option.donor.companyDisplayName"
                          :subtitle="option.donor.companyName">
                        </vs-list-item>
                      </template>

                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                      </template>
                    </v-select>
<!--                    <div class="flex mt-1" v-if="$v.dailyReportData.launch.id.$dirty">-->
<!--                      <div class="w-full">-->
<!--                        <vs-alert :active="!$v.dailyReportData.launch.id.required" :color="'danger'">-->
<!--                          Polje "Zajtrk" ne sme biti prazno.-->
<!--                        </vs-alert>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                  <div class="vx-col" v-if="drinksDonorOptions && drinksDonorOptions.length > 0">
                    <label class="vs-input--label" for="drinkDonorId">Topli napitki</label>
                    <v-select id="drinkDonorId" :options="drinksDonorOptions" class=""
                              :reduce="item => item.donor.id"
                              v-model="dailyReportData.drinkDonorId" placeholder="topli napitki"
                              :getOptionLabel="option => option.donor.companyDisplayName" @input="() => { $forceUpdate(); }">
                      <template v-slot:option="option">
                        <vs-list-item
                          :title="option.donor.companyDisplayName"
                          :subtitle="option.donor.companyName">
                        </vs-list-item>
                      </template>

                      <template v-slot:selected-option="option">
                        <vs-list-item
                          class="d-block"
                          :title="option.donor.companyDisplayName"
                          :subtitle="option.donor.companyName">
                        </vs-list-item>
                      </template>

                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                      </template>
                    </v-select>
<!--                    <div class="flex mt-1" v-if="$v.dailyReportData.launch.id.$dirty">-->
<!--                      <div class="w-full">-->
<!--                        <vs-alert :active="!$v.dailyReportData.launch.id.required" :color="'danger'">-->
<!--                          Polje "Topli napitki" ne sme biti prazno.-->
<!--                        </vs-alert>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>

                </div>

                <div class="grid grid-cols-3 gap-2 mt-5">
                  <div class="vx-col" v-if="otherDonorOptions && otherDonorOptions.length > 0">
                    <label class="vs-input--label" for="otherDonationDonorId">Druge donacije</label>
                    <v-select id="otherDonationDonorId" :options="otherDonorOptions" class=""
                              :reduce="item => item.donor.id"
                              v-model="dailyReportData.otherDonationDonorId" placeholder="druge donacije"
                              :getOptionLabel="option => option.donor.companyDisplayName" @input="() => { $forceUpdate(); }">
                      <template v-slot:option="option">
                        <vs-list-item
                          :title="option.donor.companyDisplayName"
                          :subtitle="option.donor.companyName">
                        </vs-list-item>
                      </template>

                      <template v-slot:selected-option="option">
                        <vs-list-item
                          class="d-block"
                          :title="option.donor.companyDisplayName"
                          :subtitle="option.donor.companyName">
                        </vs-list-item>
                      </template>

                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                      </template>
                    </v-select>
<!--                    <div class="flex mt-1" v-if="$v.dailyReportData.launch.id.$dirty">-->
<!--                      <div class="w-full">-->
<!--                        <vs-alert :active="!$v.dailyReportData.launch.id.required" :color="'danger'">-->
<!--                          Polje "Druge donacije" ne sme biti prazno.-->
<!--                        </vs-alert>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="vx-card mt-5">
            <div class="vx-card__header">
              <div class="vx-card__title">
                <h4 class="">Kosilo <small><em>(star način vnosa - za arhiv)</em></small></h4>
              </div>
            </div>
            <div class="vx-card__collapsible-content">
              <div class="vx-card__body">

                <div class="vx-col w-full" v-if="lunchOptionsData">
                  <v-select id="lunch" :filter="fuseSearchName" :options="lunchOptionsData" class=""
                            @input="() => { dailyReportData.launch = lunchOption; }"
                            v-model="lunchOption" placeholder="Kosilo"
                            :getOptionLabel="option => option.name">
                    <template v-slot:option="option">
                      <vs-list-item
                        :title="option.name">
                      </vs-list-item>
                    </template>

                    <template v-slot:selected-option="option">
                      <vs-list-item
                        class="d-block"
                        :title="option.name">
                      </vs-list-item>
                    </template>

                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        Ni rezultatov za iskalni pojem <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>Poskusi iskati z drugim pojmom.</em>
                    </template>
                  </v-select>

<!--                  <div class="flex mt-1" v-if="$v.dailyReportData.launch.id.$dirty">-->
<!--                    <div class="w-full">-->
<!--                      <vs-alert :active="!$v.dailyReportData.launch.id.required" :color="'danger'">-->
<!--                        Polje "Kosilo" ne sme biti prazno.-->
<!--                      </vs-alert>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>

              </div>
            </div>
          </div>


          <div class="flex mt-10 mb-20">
            <div class="w-1/2">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
                <vs-button type="border" icon="cancel" @click="$router.go(-1)" class="mt-2"
                           v-if="!dailyReportData.submitted">Prekliči
                </vs-button>
                <vs-button type="border" icon="arrow_back" @click="$router.go(-1)" class="mt-2"
                           v-if="dailyReportData.submitted">Nazaj
                </vs-button>

                <div v-if="!dailyReportData.submitted" class="ml-20">
                  <vs-button size="large" color="danger" @click="beforeDeleteDailyReport" icon="delete">Izbriši
                  </vs-button>
                </div>
              </vs-col>
            </div>

            <div class="w-1/2">
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
                <vs-button v-if="dailyReportData.submitted && $globalFunctions.isAdmin()" size="large" color="warning"
                           icon="lock_open" @click="unsubmitDailyReport">Odkleni
                </vs-button>
                <vs-button v-if="!dailyReportData.submitted" size="large" color="primary" icon="save" class="mr-20"
                           @click="saveDailyReport">Začasno shrani
                </vs-button>
                <vs-button v-if="!dailyReportData.submitted" size="large" color="success" icon="send"
                           @click="submitDailyReport">Shrani in oddaj
                </vs-button>
              </vs-col>
            </div>
          </div>


        </div>
      </div>


    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import DirectionsRenderer from '../../../helpers/DirectionsRenderer';
import vSelect from 'vue-select';
import Fuse from 'fuse.js'
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import sir from '../../../assets/images/portrait/sir.svg';
import madam from '../../../assets/images/portrait/madam.svg';
import ReservationsList from '../../Sopotniki/reservations/ReservationsList'
import {minLength, email, required} from "vuelidate/lib/validators";

export default {
  name: 'DailyReportEdit',

  components: {
    DirectionsRenderer,
    ReservationsList,
    'v-select': vSelect,
    'datepicker': Datepicker,
    'flat-pickr': flatPickr
  },
  props: {
    dailyReportId: Number
  },
  data() {
    return {
      sir,
      madam,
      slSI,

      dailyReportData: {
        car_id: null,
        cashierEnd: null,
        cashierStart: null,
        cashierTotal: null,
        created_at: null,
        date: null,
        deleted: 0,
        disabled: 0,
        donation_total: 0,
        id: null,
        launch: {},
        launch_id: null,
        notes: "",
        odometerEnd: "",
        odometerStart: "",
        odometerTotal: "",
        submitted: false,
        type: {},
        unit: {},
        unit_id: null,
        updated_at: null,
        volunteer: {},
        volunteerHoursEnd: "",
        volunteerHoursStart: "",
        volunteerHoursTotal: "",
        volunteers_id: null,
        lunchDonor: null,
        lunchDonorId: null,
        breakfastDonor: null,
        breakfastDonorId: null,
        drinkDonor: null,
        drinkDonorId: null,
        otherDonationDonor: null,
        otherDonationDonorId: null,
      },

      dailyReportTypesData: null,
      dailyReportType: {"id":1,"name":"normalno - prevozi","deleted":0,"disabled":0,"created_at":"2017-04-07T09:29:52.000Z","updated_at":null},

      volunteersData: null,
      volunteer: null,

      lunchOptionsData: null,
      lunchOption: null,

      carsData: this.$globalFunctions.getCurrentUnit().cars,
      car: null,

      configTimePicker: {
        enableTime: true,
        noCalendar: false,
        allowInput: true,
        altInput: true,
        altFormat: 'H:i',
        time_24hr: true,
        dateFormat: 'Z',
        minuteIncrement: 15
      },
      lunchDonorOptions: [],
      drinksDonorOptions: [],
      otherDonorOptions: [],
      breakfastDonorOptions: [],
    }
  },

  validations: {
    dailyReportData: {
      car: {
        id: {
          required
        }
      },
      volunteer: {
        id: {
          required
        }
      },
      odometerStart: {
        required
      },
      odometerEnd: {
        required
      },
      volunteerHoursStart: {
        required
      },
      volunteerHoursEnd: {
        required
      },
      // launch: {
      //   id: {
      //     required
      //   }
      // },
      type: {
        id: {
          required
        }
      }
    }
  },

  computed: {
    isUnitOne(){
      const selectedUnit = localStorage.getItem('selectedUnit') ? JSON.parse(localStorage.getItem('selectedUnit')) : JSON.parse(localStorage.getItem('userInfo')).units[0];
      const condition1 = selectedUnit.id === 1;
      // const condition2 = JSON.parse(localStorage.getItem('userInfo')).role === 1;
      return condition1
    }
  },

  mounted() {
    const _this = this;

    _this.$vs.loading();

    const p1 = _this.getVolunteers();
    const p2 = _this.getDailyReportTypes();
    const p3 = _this.getLunchOptions();
    const p4 =  _this.getDailyDonations();
    Promise.all([p1, p2, p3, p4]).then(() => {
      if (_this.dailyReportId) {
        _this.getDailyReport().then(() => {
          _this.$vs.loading.close();
          _this.calculateCashierTotal();
        });
      } else {
        _this.$vs.loading.close();

      }
    });
  },

  watch: {
    dailyReportId: {
      handler(val, oldVal) {
        console.log('CHANGE!!!!');
        console.log('val', val);

        if (val) {
          this.getDailyReport();
        }
      }
    },
    dailyReportData: {
      handler(val, oldVal) {
        if (val.volunteerHoursStart && val.volunteerHoursEnd) {
          this.calculateVolunteerHoursTotal();
        }
      },
      deep: true
    },
  },

  methods: {
    async getDailyReport() {
      const _this = this;

      //await Vue.prototype.$http.get(`${Vue.prototype.$config.api.productionOld}dailyReports/${_this.dailyReportId}`)
      await Vue.prototype.$http.get(`${Vue.prototype.$config.api.sopotnikiVPS}daily-reports/${_this.dailyReportId}`)
        .then((res) => {

          _this.dailyReportData = res.data.data;
          _this.dailyReportData.type = res.data.data.type ? res.data.data.type : null;
          _this.dailyReportData.volunteer = _this.volunteersData.filter((volunteer) => (volunteer.id === res.data.data.volunteer.id))[0];
          _this.dailyReportData.car = (res.data.data.car && res.data.data.car.id) ? _.filter(_this.carsData, {id: res.data.data.car.id})[0] : null;
          _this.dailyReportData.launch = res.data.data.launch ? res.data.data.launch : null;
          _this.dailyReportData.lunchDonorId = res.data.data.lunchDonor ? res.data.data.lunchDonor.id : null;
          _this.dailyReportData.breakfastDonorId = res.data.data.breakfastDonor ? res.data.data.breakfastDonor.id : null;
          _this.dailyReportData.drinkDonorId = res.data.data.drinkDonor ? res.data.data.drinkDonor.id : null;
          _this.dailyReportData.otherDonationDonorId = res.data.data.otherDonationDonor ? res.data.data.otherDonationDonor.id : null;

          _this.volunteer = res.data.data.volunteer;
          _this.dailyReportType = res.data.data.type ? res.data.data.type : null;
          _this.lunchOption = res.data.data.launch ? res.data.data.launch : null;
          _this.car = (res.data.data.car && res.data.data.car.id) ? _.filter(_this.carsData, {id: res.data.data.car.id})[0] : null;

          _this.$forceUpdate();

          console.log('dailyReport: ', res.data.data);
          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
          return false
        });
    },

    async saveDailyReport() {
      const _this = this;


      console.log(" _this.dailyReportData", _this.dailyReportData);

      _this.dailyReportData.type = _this.dailyReportData.type ? _this.dailyReportData.type : _this.dailyReportType;
      _this.dailyReportData.car = _this.dailyReportData.car ? _this.dailyReportData.car : _this.car;
      _this.dailyReportData.launch = _this.dailyReportData.launch ? _this.dailyReportData.launch : _this.lunchOption;
      _this.dailyReportData.volunteer = _this.dailyReportData.volunteer ? _this.dailyReportData.volunteer : _this.volunteer;

      let data = {
        id: _this.dailyReportData.id, //If set then UPDATE
        notes: _this.dailyReportData.notes,
        type: _this.dailyReportData.type ? _this.dailyReportData.type.id : null,
        launch: _this.dailyReportData.launch ? _this.dailyReportData.launch.id : null,
        unit: _this.$globalFunctions.getCurrentUnit().id,
        car: _this.dailyReportData.car ? _this.dailyReportData.car.id : null,
        volunteer: _this.dailyReportData.volunteer ? _this.dailyReportData.volunteer.id : null,
        donation_total: _this.dailyReportData.donation_total,
        odometerStart: _this.dailyReportData.odometerStart,
        odometerEnd: _this.dailyReportData.odometerEnd,
        odometerTotal: _this.dailyReportData.odometerTotal,
        volunteerHoursStart: _this.moment(_this.dailyReportData.volunteerHoursStart).valueOf(),
        volunteerHoursEnd: _this.moment(_this.dailyReportData.volunteerHoursEnd).valueOf(),
        volunteerHoursTotal: _this.dailyReportData.volunteerHoursTotal,
        cashierStart: _this.dailyReportData.cashierStart,
        cashierEnd: _this.dailyReportData.cashierEnd,
        cashierTotal: _this.dailyReportData.cashierTotal,
        submitted: _this.dailyReportData.submitted || _this.dailyReportData.submitted === 1 ? true : false,
        lunchDonorId: _this.dailyReportData.lunchDonorId ? _this.dailyReportData.lunchDonorId : null,
        breakfastDonorId: _this.dailyReportData.breakfastDonorId ? _this.dailyReportData.breakfastDonorId : null,
        drinkDonorId: _this.dailyReportData.drinkDonorId ? this.dailyReportData.drinkDonorId : null,
        otherDonationDonorId: _this.dailyReportData.otherDonationDonorId ? _this.dailyReportData.otherDonationDonorId : null,
      };

      // validate on submit action
      if (_this.dailyReportData.submitted) {
        this.$v.$touch();
        this.$nextTick(() => this.focusFirstStatus());
        if (this.$v.$invalid) {
          console.log("this.$v.", this.$v);
          return;
        }
      }

      _this.$vs.loading();

      console.log("_this.dailyReportData", _this.dailyReportData);

      await Vue.prototype.$http.put(`${Vue.prototype.$config.api.sopotnikiVPS}daily-reports/${_this.dailyReportData.id}`, data)
        .then((res) => {

          console.log('dailyReport saved: ', res.data.data);
          _this.$vs.notify({
            color: 'success',
            title: 'Dnevno poročilo uspešno shranjeno!',
            text: '',
            position: 'top-right'
          });
          _this.$vs.loading.close();
          _this.$router.go(-1);
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
          return false
        });

    },

    async submitDailyReport() {
      const _this = this;

      _this.dailyReportData.submitted = true;
      _this.saveDailyReport();
    },

    async unsubmitDailyReport() {
      const _this = this;

      _this.dailyReportData.submitted = false;
      _this.saveDailyReport();
    },

    async getVolunteers() {
      const _this = this;

      await Vue.prototype.$http.get(`${Vue.prototype.$config.api.productionOld}volunteers/unit/${_this.$globalFunctions.getCurrentUnit().id}`)
        .then((res) => {
          _this.volunteersData = res.data.data.filter((volunteer) => {return volunteer.active === 1});
          console.log("volunteersData 222: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async getDailyReportTypes() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'reportTypes')
        .then((res) => {
          _this.dailyReportTypesData = res.data.data;
          console.log("dailyReportTypesData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async getLunchOptions() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'launch')
        .then((res) => {
          _this.lunchOptionsData = _.filter(res.data.data, {unit_id: _this.$globalFunctions.getCurrentUnit().id});
          console.log("lunchOptionsData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async getDailyDonations() {
      const _this = this;

      _this.$vs.loading();

      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'daily-donation-schedule')
        .then((res) => {

          let activeDailyDonations = _.filter(res.data.data, {active: 1});
          let activeDailyDonationsForUnit = _.filter( activeDailyDonations, (item) => item.unit.id === _this.$globalFunctions.getCurrentUnit().id);

          _this.lunchDonorOptions = _.filter(activeDailyDonationsForUnit, (item) => item.type.id === 1);
          _this.drinksDonorOptions = _.filter(activeDailyDonationsForUnit, (item) => item.type.id === 2);
          _this.otherDonorOptions = _.filter(activeDailyDonationsForUnit, (item) => item.type.id === 9);
          _this.breakfastDonorOptions = _.filter(activeDailyDonationsForUnit, (item) => item.type.id === 8);

          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    beforeDeleteDailyReport() {
      const _this = this;

      Vue.$confirm({
        title: 'Ste prepričani, da želite izbrisati dnevno poročilo za dan ' + _this.moment(_this.dailyReportData.date).format('dddd, DD. MM. YYYY') + '?',
        message: 'Po potrditvi bo dnevno poročilo trajno izbrisano.',
        button: {
          yes: 'Izbriši',
          no: 'Prekliči'
        },
        callback: confirm => {
          console.log("confirm", confirm);
          if (confirm) {
            _this.deleteDailyReport();
          }
        }
      });
    },

    async deleteDailyReport() {
      const _this = this;

      _this.$vs.loading();

      await Vue.prototype.$http.delete(`${Vue.prototype.$config.api.productionOld}dailyReport/${_this.dailyReportData.id}`)
        .then((res) => {

          console.log('dailyReport deleted');
          _this.$vs.loading.close();
          _this.$router.push({name: 'Dnevna poročila'});

        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
          return false
        });

    },

    customDateFormatter(date) {
      console.log("date", date);
      return this.moment(date).format('DD. MM. YYYY');
    },

    onDateChange() {
      const _this = this;
      // _this.$vs.loading();
      //
      // _this.getVolunteers().then(() => {
      //   _this.getDailyReports().then(() => {
      //     _this.$vs.loading.close();
      //   });
      // });

    },

    calculateOdometerTotal() {
      const _this = this;

      if (_this.dailyReportData.odometerStart && _this.dailyReportData.odometerEnd) {
        _this.dailyReportData.odometerTotal = _this.dailyReportData.odometerEnd - _this.dailyReportData.odometerStart;
      }

    },

    calculateCashierTotal() {
      const _this = this;

      if (_this.dailyReportData.cashierStart && _this.dailyReportData.cashierEnd) {
        _this.dailyReportData.cashierTotal = Math.round((_this.dailyReportData.cashierEnd - _this.dailyReportData.cashierStart) * 100) / 100;
      }
    },

    calculateVolunteerHoursTotal() {
      const _this = this;

      if (_this.dailyReportData.volunteerHoursStart && _this.dailyReportData.volunteerHoursEnd) {

        let start = _this.moment(_this.dailyReportData.date).hour(this.moment(_this.dailyReportData.volunteerHoursStart).format('HH')).minute(this.moment(_this.dailyReportData.volunteerHoursStart).format('mm'));
        let end = _this.moment(_this.dailyReportData.date).hour(this.moment(_this.dailyReportData.volunteerHoursEnd).format('HH')).minute(this.moment(_this.dailyReportData.volunteerHoursEnd).format('mm'));

        let duration = _this.moment.duration(end.diff(start)).subtract(1, 'hours');

        _this.dailyReportData.volunteerHoursTotal = _this.moment(new Date(duration)).format("HH:mm");

      }
    },

    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["first_name", "last_name", "addresses.quick_search"],
        minMatchCharLength: 3,
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({item}) => item)
        : fuse.list;
    },

    fuseSearchName(options, search) {
      const fuse = new Fuse(options, {
        keys: ["name"],
        minMatchCharLength: 2,
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({item}) => item)
        : fuse.list;
    },

    focusFirstStatus() {
      let domRect = document.querySelector('.vs-alert').getBoundingClientRect();
      window.scrollTo(
        domRect.left + document.documentElement.scrollLeft,
        domRect.top + document.documentElement.scrollTop - 200, 'smooth'
      );
    },

  }
}
</script>

<style lang="scss">
// ugly hack for timepicker !!!
.flatpickr-innerContainer,
.flatpickr-months {
  display: none !important;
}
</style>
